import { get, includes, replace, trimEnd, toNumber } from 'lodash-es';
import moment from 'moment-timezone';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { parseAssignmentDates } from '../utils/parseAssignmentDates';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { AssignmentTimelineItem } from '../types';
import { formatDate } from '~/services/datetime';
import { AssignmentTimelineElement } from '../components/AssignmentTimelineElement';

export function getDefaultAssignmentTimelineItems(contentItem: ContentItem) {
  const timelineElements: AssignmentTimelineItem[] = [];

  const { assignment, ...assignmentDates } = parseAssignmentDates(contentItem);

  if (!assignment) {
    return timelineElements;
  }

  const contentType = contentItem.content_type;

  const assignmentState = get(assignment, 'state');
  const assignmentProgress = toNumber(get(assignment, 'progress.progress', '0')) * 100;
  const assignmentScore = toNumber(get(assignment, 'completion.score', '0')) * 100;

  const assignedBy = get(contentItem, 'user_assignment.created_by_id');
  if (assignedBy) {
    timelineElements.push({
      key: 'assigned_by',
      element: <AssignmentTimelineElement.AssignedBy assignedById={assignedBy} />,
    });
  }

  if (assignmentDates.assignedDate) {
    timelineElements.push({
      key: 'assigned_date',
      element: (
        <AssignmentTimelineElement.Text>
          {`Assigned ${formatDate(assignmentDates.assignedDate)}`}
        </AssignmentTimelineElement.Text>
      ),
    });
  }

  if (assignmentDates.startedDate) {
    timelineElements.push({
      key: 'started_date',
      element: (
        <AssignmentTimelineElement.Text>
          {`Started ${formatDate(assignmentDates.startedDate)}`}
        </AssignmentTimelineElement.Text>
      ),
    });
  }

  if (assignmentState !== ASSIGNMENT_STATES.completed && assignmentProgress) {
    timelineElements.push({
      key: 'progress',
      element: <AssignmentTimelineElement.Progress progress={assignmentProgress} />,
    });
  }

  if (
    assignmentDates.dueDate &&
    !includes([ASSIGNMENT_STATES.completed, ASSIGNMENT_STATES.exempted], assignmentState)
  ) {
    const isOverdue = moment().isAfter(moment(assignmentDates.dueDate).endOf('day'));

    if (isOverdue) {
      timelineElements.push({
        key: 'overdue_date',
        element: <AssignmentTimelineElement.Overdue date={assignmentDates.dueDate} />,
      });
    } else {
      timelineElements.push({
        key: 'due_date',
        element: <AssignmentTimelineElement.Due date={assignmentDates.dueDate} />,
      });
    }
  }

  if (assignmentState === ASSIGNMENT_STATES.dropped && assignmentDates.droppedDate) {
    timelineElements.push({
      key: 'dropped_date',
      element: (
        <AssignmentTimelineElement.Text>
          {`Dropped ${formatDate(assignmentDates.droppedDate)}`}
        </AssignmentTimelineElement.Text>
      ),
    });
  }

  if (assignmentState === ASSIGNMENT_STATES.exempted && assignmentDates.exemptedDate) {
    timelineElements.push({
      key: 'exempted_date',
      element: <AssignmentTimelineElement.Exempted date={assignmentDates.exemptedDate} />,
    });
  }

  if (assignmentState === ASSIGNMENT_STATES.completed && assignmentDates.completedDate) {
    timelineElements.push({
      key: 'completed_date',
      element: <AssignmentTimelineElement.Completed date={assignmentDates.completedDate} />,
    });
  }

  if (
    contentType === CONTENT_TYPES.assessment &&
    assignmentState === ASSIGNMENT_STATES.completed &&
    assignmentScore
  ) {
    const parsedScore = replace(trimEnd(assignmentScore.toFixed(2), '0'), /\.$/, '');

    timelineElements.push({
      key: 'score',
      element: (
        <AssignmentTimelineElement.Text>{`Score: ${parsedScore}%`}</AssignmentTimelineElement.Text>
      ),
    });
  }

  return timelineElements;
}
