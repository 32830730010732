import * as React from 'react';

import { ContentItemHeader } from '~/common/components/ContentItemHeader';
import ChannelByline from '~/app/shared/components/ChannelByline';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useContentPeople } from '~/app/catalog/hooks';
import { get } from 'lodash-es';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import { Box, Stack } from '@mui/material';
import { CONTENT_TYPES } from '~/app/catalog/constants';
import { getContentItemFreshness } from '~/features/contentitems/utils/getContentItemFreshness';
import { AssignmentTimeline } from './AssignmentTimeline';
import { AssignmentActionsProvider } from './AssignmentActionsProvider';
import { useIsPreviewQueryParam } from '~/app/shared/hooks';
import { DefaultAssignmentPrimaryAction } from './DefaultAssignmentPrimaryAction';
import { DefaultAssignmentSecondaryAction } from './DefaultAssignmentSecondaryAction';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { ContentRatingActions } from '~/common/components/ContentRatingActions';
import { AssignmentTimelineItem } from '../types';
import { getDefaultAssignmentTimelineItems } from '../utils/getDefaultAssignmentTimelineItems';

type DefaultContentItemHeaderProps = {
  contentItem: ContentItem;
  contextMenu?: React.ReactNode;
  isOptional?: boolean;
  timeline?: AssignmentTimelineItem[];
  PrimaryAction?: React.ElementType<any> | null;
  SecondaryAction?: React.ElementType<any> | null;
};

function DefaultContentItemHeader(props: DefaultContentItemHeaderProps) {
  const {
    contentItem,
    contextMenu,
    isOptional = false,
    timeline,
    PrimaryAction: PrimaryActionProp,
    SecondaryAction: SecondaryActionProp,
  } = props;

  const isPreviewMode = useIsPreviewQueryParam();
  const isRenderedWithinTrack = useIsRenderedWithinTrack();
  const { maintainers } = useContentPeople(contentItem);

  const assignment = contentItem.user_assignment || contentItem.assignment;
  const assignmentState = get(assignment, 'state');

  const tags = get(contentItem, 'tags', []);
  const isArchived = get(
    contentItem,
    'is_archived',
    Boolean(get(contentItem, 'archival.archived_at'))
  );
  const isTask = get(contentItem, 'content_type', '') === CONTENT_TYPES.task;
  const isInline = get(contentItem, 'is_inline', false);
  const freshnessInfo = getContentItemFreshness(contentItem);

  const timelineItems = timeline || getDefaultAssignmentTimelineItems(contentItem);

  const canClickTitle = React.useMemo(() => {
    if (isTask) {
      return false;
    }

    if (isInline) {
      return false;
    }

    return isRenderedWithinTrack;
  }, [isTask, isInline, isRenderedWithinTrack]);

  const PrimaryAction =
    PrimaryActionProp !== undefined ? PrimaryActionProp : DefaultAssignmentPrimaryAction;
  const canDisplayPrimaryAction = !isPreviewMode && PrimaryAction != null;

  const SecondaryAction =
    SecondaryActionProp !== undefined ? SecondaryActionProp : DefaultAssignmentSecondaryAction;
  const canDisplaySecondaryAction = SecondaryAction != null;

  const canDisplayAssignmentActions = !isRenderedWithinTrack && !isArchived;

  return (
    <ContentItemHeader>
      <ContentItemHeader.HeadingArea>
        {contentItem.cover && <ContentItemHeader.Cover component="img" src={contentItem.cover} />}

        <ContentItemHeader.ContentArea
          sx={{
            gap: '8px',
          }}
        >
          <ContentItemHeader.TitleArea>
            <ContentItemHeader.Title
              contentItem={contentItem}
              isClickable={canClickTitle}
              isOptional={isOptional}
              subTitle={<ContentItemHeader.Metadata contentItem={contentItem} />}
            />

            {contextMenu && (
              <ContentItemHeader.ContextMenuArea>{contextMenu}</ContentItemHeader.ContextMenuArea>
            )}
          </ContentItemHeader.TitleArea>

          {(contentItem.channel || freshnessInfo) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {contentItem.channel && <ChannelByline channel={contentItem.channel} />}

              <Box flex={1} />

              {freshnessInfo && (
                <ContentItemHeader.Freshness info={freshnessInfo} maintainers={maintainers} />
              )}
            </Box>
          )}

          {tags.length > 0 && <ContentItemHeader.TagList tags={tags} />}
        </ContentItemHeader.ContentArea>
      </ContentItemHeader.HeadingArea>

      <ContentItemHeader.FooterArea>
        {timelineItems.length > 0 && <AssignmentTimeline items={timelineItems} />}

        <Box flex={1} />

        {canDisplayAssignmentActions && (
          <AssignmentActionsProvider content={contentItem} assignment={assignment}>
            <Stack direction="row" gap="12px" alignItems="center">
              {canDisplaySecondaryAction && <SecondaryAction content={contentItem} />}

              {assignment && assignmentState === ASSIGNMENT_STATES.completed && (
                <ContentRatingActions content={contentItem} assignment={assignment} />
              )}

              {canDisplayPrimaryAction && <PrimaryAction content={contentItem} />}
            </Stack>
          </AssignmentActionsProvider>
        )}
      </ContentItemHeader.FooterArea>
    </ContentItemHeader>
  );
}

export { DefaultContentItemHeader };
