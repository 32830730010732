import CircularProgressWithLabel from '~/app/shared/components/CircularProgressWithLabel/CircularProgressWithLabel';
import colors from '~/services/colors';
import { formatDate } from '~/services/datetime';
import { Chip, TypographyProps, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ErrorIcon from '@mui/icons-material/Error';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import { PeoplePill } from '~/common/components/PeoplePill';
import { mapRoute } from '~/services/requests';
import { User } from '~/app/shared/components/types';
import { queries } from '~/queries';
import { QueryFunction, useQuery } from '@tanstack/react-query';

const TimelineText = styled((props: TypographyProps) => <Typography variant="body1" {...props} />)({
  fontSize: '13px',
  color: colors.neutral700,
});

type AssignedByItemProps = {
  assignedById: number;
};

function AssignedByItem(props: AssignedByItemProps) {
  const { assignedById } = props;

  const queryOpts = queries.users.detail(assignedById, 'lite');
  const { data: assignedBy, status: assignedByStatus } = useQuery({
    queryKey: queryOpts.queryKey,
    queryFn: queryOpts.queryFn as QueryFunction<User>,
  });

  if (assignedByStatus === 'loading') {
    return <Skeleton variant="text" width={100} />;
  }

  if (!assignedBy) {
    return null;
  }

  return (
    <PeoplePill
      image={assignedBy?.profile_image}
      name={assignedBy?.display_name}
      route={mapRoute('userProfile', { id: `${assignedBy?.id}` })}
    />
  );
}

type ProgressItemProps = {
  progress: number;
};

function ProgressItem(props: ProgressItemProps) {
  const { progress } = props;

  return (
    <CircularProgressWithLabel
      variant="determinate"
      size={30}
      value={progress}
      title={`${progress}%`}
    />
  );
}

type DueItemProps = {
  date: string;
};

function DueItem(props: DueItemProps) {
  const { date } = props;

  return (
    <Chip
      icon={<ScheduleIcon fontSize="small" color="inherit" />}
      label={`Due ${formatDate(date)}`}
      size="small"
      sx={{
        backgroundColor: '#fff3e0',
        color: '#e65100',
      }}
    />
  );
}

type OverdueItemProps = {
  date: string;
};

function OverdueItem(props: OverdueItemProps) {
  const { date } = props;

  return (
    <Chip
      icon={<ErrorIcon fontSize="small" color="inherit" />}
      label={`Overdue ${formatDate(date)}`}
      size="small"
      sx={{
        backgroundColor: '#ffebee',
        color: '#c62828',
      }}
    />
  );
}

type CompletedItemProps = {
  date: string;
  label?: string;
};

function CompletedItem(props: CompletedItemProps) {
  const { date, label = 'Completed' } = props;

  return (
    <Chip
      icon={<CheckIcon fontSize="small" color="inherit" />}
      label={`${label} ${formatDate(date)}`}
      size="small"
      sx={{
        backgroundColor: '#c8e6c9',
        color: '#1b5e20',
      }}
    />
  );
}

type ExemptedItemProps = {
  date: string;
};

function ExemptedItem(props: ExemptedItemProps) {
  const { date } = props;

  return (
    <Chip
      icon={<UnpublishedOutlinedIcon fontSize="small" color="inherit" />}
      label={`Exempted ${formatDate(date)}`}
      size="small"
      sx={{
        backgroundColor: '#e1f5fe',
        color: '#01579b',
      }}
    />
  );
}

export const AssignmentTimelineElement = Object.assign(
  {},
  {
    Text: TimelineText,
    AssignedBy: AssignedByItem,
    Progress: ProgressItem,
    Due: DueItem,
    Overdue: OverdueItem,
    Completed: CompletedItem,
    Exempted: ExemptedItem,
  }
);
