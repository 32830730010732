import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { get } from 'lodash-es';
import { PickEventChip } from '~/features/event_types/components/PickEventChip';

interface PrimaryActionButtonProps {
  content: ContentItem;
}

const PrimaryActionButton = ({ content }: PrimaryActionButtonProps) => {
  const isCompleted = get(content, 'user_assignment.state') === ASSIGNMENT_STATES.completed;

  if (isCompleted) return null;

  return <PickEventChip />;
};

export default PrimaryActionButton;
