import * as React from 'react';

import Page404 from '~/scenes/404';
import EventDetailPage from '~/scenes/EventDetail/EventDetailPage';
import Loading from '~/app/shared/components/Loading';
import { useSetDetailedObject, usePublicIdFromURL } from '~/app/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';

const EventDetail = () => {
  const { publicId } = usePublicIdFromURL();

  const { data: event, status } = useQuery({
    ...queries.events.detailLegacy(publicId),
    enabled: !!publicId,
  });

  useSetDetailedObject({ id: publicId }, 'events', [publicId]);

  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'error' || !event) {
    return <Page404 />;
  }

  return <EventDetailPage event={event} />;
};

export default EventDetail;
