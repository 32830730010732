import { useState } from 'react';
import styled from 'styled-components';

import Button from '~/app/shared/components/Button';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { canBeDropped } from '~/app/assignments/services';
import { CONTENT_TYPES, DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME } from '~/app/catalog/constants';
import { Course } from '~/app/course/interfaces';
import { useAssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import ButtonLink from '~/app/shared/components/ButtonLink';
import { useToggles, useIsPreviewQueryParam } from '~/app/shared/hooks';
import DropTrackItemWarnModal from '~/app/stand-alone-shared/components/DropTrackItemWarnModal';
import { includes, get, map, isEmpty } from 'lodash-es';
import { BookmarkBorderOutlined, HighlightOffIcon } from '~/vendor/mui-icons';
import OldSecondaryActionButton from '~/app/shared-content-item/components/OldSecondaryActionButton';

const Container = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
`;

type DropTrackItemConflictInfo = {
  dependents: ContentItem[];
};

type DefaultAssignmentSecondaryActionProps = {
  content: ContentItem;
};

function DefaultAssignmentSecondaryAction(props: DefaultAssignmentSecondaryActionProps) {
  const { content } = props;

  const { dev_toggle_content_assignments_2_0: devToggleContentAssignment2 } = useToggles();

  const [dropTrackItemConflictInfo, setDropTrackItemConflictInfo] =
    useState<DropTrackItemConflictInfo | null>(null);
  const showDropTrackItemWarnModal = dropTrackItemConflictInfo != null;

  const { content_type: contentType, is_completable: isCompletable } = content;
  const isCourse = contentType === CONTENT_TYPES.course;

  const assignment = content?.user_assignment || content?.assignment;
  const assignmentState = get(assignment, 'state');

  const { create, drop, undoCompletion, isLoading } = useAssignmentActionsCtx();

  const isPreviewMode = useIsPreviewQueryParam();

  const handleDrop = () => {
    drop(
      {},
      {
        onError(error) {
          const dropError = get(error, 'data.exception');

          if (dropError === DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME) {
            setDropTrackItemConflictInfo({
              dependents: get(error, 'data.dependents', []),
            });
          }
        },
      }
    );
  };

  const handleCloseDropTrackItemWarnModal = () => {
    setDropTrackItemConflictInfo(null);
  };

  if (!devToggleContentAssignment2) {
    return <OldSecondaryActionButton content={content} contentType={contentType || ''} />;
  }

  if (
    !isCompletable &&
    includes(
      [
        CONTENT_TYPES.article,
        CONTENT_TYPES.program,
        CONTENT_TYPES.video,
        CONTENT_TYPES.linkedcontent,
      ],
      contentType
    )
  ) {
    return null;
  }

  if (assignmentState === ASSIGNMENT_STATES.completed && !isPreviewMode) {
    return (
      <Container>
        <ButtonLink onClick={undoCompletion} variant="error" disabled={isLoading}>
          Undo
        </ButtonLink>
      </Container>
    );
  }

  // The code is dealing with both formats, because the old content item endpoint is still
  // returning the assignment in the old format, as long as the migration to CA.2.0 is not
  // done completely, we have to deal with these two formats.
  const dueDate = get(assignment, 'due_date') || get(assignment, 'due_at');
  const hasDueDate = dueDate != null;

  if (canBeDropped(assignment) && !hasDueDate) {
    return (
      <Container>
        <Button onClick={handleDrop} startIcon={<HighlightOffIcon />} disabled={isLoading}>
          Drop
        </Button>

        {showDropTrackItemWarnModal && (
          <DropTrackItemWarnModal
            handleClose={handleCloseDropTrackItemWarnModal}
            unassign={() => drop({ force: true })}
            content={content}
            tracks={map(get(dropTrackItemConflictInfo, 'dependents', []), 'content_item')}
          />
        )}
      </Container>
    );
  }

  const isSaveButtonDisabled =
    isLoading || (isCourse && isEmpty((content as Course)?.scorm_cloud_id));

  return (
    <Container>
      <Button
        onClick={() => create()}
        disabled={isSaveButtonDisabled}
        startIcon={<BookmarkBorderOutlined />}
      >
        Save
      </Button>
    </Container>
  );
}

export { DefaultAssignmentSecondaryAction };
