import { get, includes } from 'lodash-es';
import moment from 'moment-timezone';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { formatDate } from '~/services/datetime';
import { AssignmentTimelineElement } from '~/features/contentitems/components/AssignmentTimelineElement';
import { AssignmentTimelineItem } from '~/features/contentitems/types';
import { parseAssignmentDates } from '~/features/contentitems/utils/parseAssignmentDates';
import { PickEventChip } from '../components/PickEventChip';

export function getEventTypeAssignmentTimelineItems(contentItem: ContentItem) {
  const timelineElements: AssignmentTimelineItem[] = [];

  const { assignment, ...assignmentDates } = parseAssignmentDates(contentItem);

  if (!assignment) {
    timelineElements.push({
      key: 'pick_event',
      element: <PickEventChip />,
    });

    return timelineElements;
  }

  const assignmentState = get(assignment, 'state');

  const assignedBy = get(contentItem, 'user_assignment.created_by_id');
  if (assignedBy) {
    timelineElements.push({
      key: 'assigned_by',
      element: <AssignmentTimelineElement.AssignedBy assignedById={assignedBy} />,
    });
  }

  if (assignmentDates.assignedDate) {
    timelineElements.push({
      key: 'assigned_date',
      element: (
        <AssignmentTimelineElement.Text>
          {`Assigned ${formatDate(assignmentDates.assignedDate)}`}
        </AssignmentTimelineElement.Text>
      ),
    });
  }

  if (assignmentDates.startedDate) {
    timelineElements.push({
      key: 'started_date',
      element: (
        <AssignmentTimelineElement.Text>
          {`Started ${formatDate(assignmentDates.startedDate)}`}
        </AssignmentTimelineElement.Text>
      ),
    });
  }

  if (!includes([ASSIGNMENT_STATES.completed, ASSIGNMENT_STATES.exempted], assignmentState)) {
    timelineElements.push({
      key: 'pick_event',
      element: <PickEventChip />,
    });
  }

  if (
    assignmentDates.dueDate &&
    !includes([ASSIGNMENT_STATES.completed, ASSIGNMENT_STATES.exempted], assignmentState)
  ) {
    const isOverdue = moment().isAfter(moment(assignmentDates.dueDate).endOf('day'));

    if (isOverdue) {
      timelineElements.push({
        key: 'overdue_date',
        element: <AssignmentTimelineElement.Overdue date={assignmentDates.dueDate} />,
      });
    } else {
      timelineElements.push({
        key: 'due_date',
        element: <AssignmentTimelineElement.Due date={assignmentDates.dueDate} />,
      });
    }
  }

  if (assignmentState === ASSIGNMENT_STATES.dropped && assignmentDates.droppedDate) {
    timelineElements.push({
      key: 'dropped_date',
      element: (
        <AssignmentTimelineElement.Text>
          {`Dropped ${formatDate(assignmentDates.droppedDate)}`}
        </AssignmentTimelineElement.Text>
      ),
    });
  }

  if (assignmentState === ASSIGNMENT_STATES.exempted && assignmentDates.exemptedDate) {
    timelineElements.push({
      key: 'exempted_date',
      element: <AssignmentTimelineElement.Exempted date={assignmentDates.exemptedDate} />,
    });
  }

  if (assignmentState === ASSIGNMENT_STATES.completed && assignmentDates.completedDate) {
    timelineElements.push({
      key: 'completed_date',
      element: <AssignmentTimelineElement.Completed date={assignmentDates.completedDate} />,
    });
  }

  return timelineElements;
}
