import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import colors from '~/services/colors';
import Button from '~/app/shared/components/Button';
import Loading from '~/app/shared/components/Loading';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from '~/app/shared/components/Modal';
import Text from '~/app/shared/components/Text';
import { Stack } from '@mui/material';
import {
  ConnectWithoutContactIcon,
  HighlightOffIcon,
  PersonPinCircleIcon,
} from '~/vendor/mui-icons';

const DropWaitlistModal = ({ handleClose, performLeaveWaitlist, isLocal }) => {
  return (
    <Modal handleClose={handleClose} title="Drop Waitlist">
      <ModalBody>
        <Text block size="h5" colors={colors.neutral900}>
          You&#39;re about to drop the {isLocal ? 'local' : 'online'} waitlist. Are you sure you{' '}
          want to do this?
        </Text>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton onClick={handleClose}>Cancel</ModalFooterButton>
        <ModalFooterButton color="error" onClick={performLeaveWaitlist}>
          Drop
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

DropWaitlistModal.propTypes = {
  handleClose: PropTypes.func,
  performLeaveWaitlist: PropTypes.func,
  isLocal: PropTypes.bool,
};

export function WaitlistActionButtons({ actions, status }) {
  const { performJoinLocalWaitlist, performJoinOnlineWaitlist, performLeaveWaitlist } = actions;
  const { userIsOnLocalWaitlist, userIsOnOnlineWaitlist, showLocalWaitlist, showOnlineWaitlist } =
    status;

  const [showDropWaitlistModal, setShowDropWaitlistModal] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);

  const canWaitlistLocal = userIsOnOnlineWaitlist && showLocalWaitlist;
  const canWaitlistOnline = userIsOnLocalWaitlist && showOnlineWaitlist;

  const switchToOnlineWaitlist = () => {
    setIsSwitching(true);
    performJoinOnlineWaitlist();
  };

  const switchToLocalWaitlist = () => {
    setIsSwitching(true);
    performJoinLocalWaitlist();
  };

  useEffect(() => {
    setIsSwitching(false);
  }, [userIsOnLocalWaitlist, userIsOnOnlineWaitlist]);

  if (isSwitching) {
    return <Loading hasMargin={false} size={12} />;
  }

  return (
    <>
      <Stack direction="row" spacing="12px">
        <Button onClick={() => setShowDropWaitlistModal(true)} startIcon={<HighlightOffIcon />}>
          Drop
        </Button>

        {canWaitlistLocal && (
          <Button
            variant="contained"
            onClick={switchToLocalWaitlist}
            startIcon={<PersonPinCircleIcon />}
          >
            Switch to In-person Waitlist
          </Button>
        )}

        {canWaitlistOnline && (
          <Button
            variant="contained"
            onClick={switchToOnlineWaitlist}
            startIcon={<ConnectWithoutContactIcon />}
          >
            Switch to Online Waitlist
          </Button>
        )}
      </Stack>

      {showDropWaitlistModal && (
        <DropWaitlistModal
          handleClose={() => setShowDropWaitlistModal(false)}
          performLeaveWaitlist={performLeaveWaitlist}
          isLocal={userIsOnLocalWaitlist}
        />
      )}
    </>
  );
}

WaitlistActionButtons.propTypes = {
  actions: PropTypes.object,
  status: PropTypes.object,
};
