import { get } from 'lodash-es';
import { formatDate } from '~/services/datetime';
import { Enrollment } from '~/app/enrollments/types';
import { Event } from '~/app/event/interfaces';
import { AssignmentTimelineElement } from '~/features/contentitems/components/AssignmentTimelineElement';
import { Chip, Typography } from '@mui/material';
import {
  ATTENDANCE_METHODS,
  ATTENDANCE_METHODS_ICONS,
  ATTENDANCE_METHODS_LABELS,
  ATTENDANCE_STATUSES,
} from '~/app/enrollments/constants';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import colors from '~/services/colors';
import { getOrdinalNumber } from '~/services/utils';
import { AssignmentTimelineItem } from '~/features/contentitems/types';

export function getEnrollmentTimelineItems(event: Event) {
  const timelineElements: AssignmentTimelineItem[] = [];

  const enrollment = get(event, 'enrollment');
  if (!enrollment) {
    return timelineElements;
  }

  const enrollmentDates = parseEnrollmentDates(enrollment);

  const attendanceMethod = get(enrollment, 'attendance_method');
  const attendanceStatus = get(enrollment, 'attendance_status');

  const assignedBy = get(enrollment, 'created_by_id');
  if (assignedBy) {
    timelineElements.push({
      key: 'assigned_by',
      element: <AssignmentTimelineElement.AssignedBy assignedById={assignedBy} />,
    });
  }

  if (enrollmentDates.createdAt) {
    const label = attendanceStatus === ATTENDANCE_STATUSES.waitlisted ? 'Waitlisted' : 'Enrolled';

    timelineElements.push({
      key: 'assigned_date',
      element: (
        <AssignmentTimelineElement.Text>
          {`${label} ${formatDate(enrollmentDates.createdAt)}`}
        </AssignmentTimelineElement.Text>
      ),
    });
  }

  if (
    attendanceStatus === ATTENDANCE_STATUSES.waitlisted &&
    attendanceMethod !== ATTENDANCE_METHODS.unknown
  ) {
    const currentSpot: number | undefined =
      attendanceMethod === ATTENDANCE_METHODS.local
        ? get(enrollment, 'waitlist_position')
        : get(enrollment, 'waitlist_online_position');

    if (currentSpot) {
      timelineElements.push({
        key: 'waitlist_spot',
        element: (
          <Chip
            icon={
              <HourglassBottomIcon
                fontSize="small"
                color="inherit"
                sx={{ fontSize: '16px !important' }}
              />
            }
            label={
              <Typography fontSize="inherit" color="inherit">
                <b>{getOrdinalNumber(currentSpot)}</b> on the waitlist
              </Typography>
            }
            size="small"
            variant="outlined"
            sx={{
              borderStyle: 'dashed',
              color: colors.neutral700,
            }}
          />
        ),
      });
    }
  }

  if (attendanceMethod && attendanceMethod !== ATTENDANCE_METHODS.unknown) {
    timelineElements.push({
      key: 'attendance_method',
      element: <AttendanceMethodChip attendanceMethod={attendanceMethod} />,
    });
  }

  if (attendanceStatus === ATTENDANCE_STATUSES.no_show) {
    timelineElements.push({
      key: 'no_show',
      element: (
        <Chip
          icon={<PriorityHighOutlinedIcon fontSize="small" color="inherit" />}
          label="No Show"
          size="small"
          sx={{
            backgroundColor: '#ffebee',
            color: '#c62828',
          }}
        />
      ),
    });
  }

  if (attendanceStatus === ATTENDANCE_STATUSES.cancelled) {
    timelineElements.push({
      key: 'cancelled',
      element: (
        <Chip
          icon={<CancelOutlinedIcon fontSize="small" color="inherit" />}
          label="Cancelled"
          size="small"
          sx={{
            backgroundColor: '#fbe9e7',
            color: '#bf360c',
          }}
        />
      ),
    });
  }

  if (attendanceStatus === ATTENDANCE_STATUSES.checked_in && enrollmentDates.checkedInAt) {
    timelineElements.push({
      key: 'checked_in',
      element: (
        <AssignmentTimelineElement.Completed label="Attended" date={enrollmentDates.checkedInAt} />
      ),
    });
  }

  return timelineElements;
}

type AttendanceMethodChipProps = {
  attendanceMethod: string;
};

function AttendanceMethodChip(props: AttendanceMethodChipProps) {
  const { attendanceMethod } = props;

  const label: string = ATTENDANCE_METHODS_LABELS[attendanceMethod];
  const Icon: (typeof ATTENDANCE_METHODS_ICONS)[keyof typeof ATTENDANCE_METHODS_ICONS] | undefined =
    ATTENDANCE_METHODS_ICONS[attendanceMethod];

  return (
    <Chip
      icon={Icon ? <Icon fontSize="small" color="inherit" /> : undefined}
      label={label}
      size="small"
      sx={{
        backgroundColor: '#e1f5fe',
        color: '#01579b',
      }}
    />
  );
}

function parseEnrollmentDates(enrollment: Enrollment) {
  const createdAt = get(enrollment, 'created') as unknown as string;
  const checkedInAt = get(enrollment, 'checkin_datetime') as unknown as string;

  return {
    createdAt,
    checkedInAt,
  };
}
