import styled from 'styled-components';

import { LEARNING_TYPES } from '~/app/catalog/constants';
import { useContentPeople } from '~/app/catalog/hooks';
import { useLatestContentItemAssignment } from '~/common/hooks/useLatestContentItemAssignment';
import ContentItemContainer from '~/app/content-items/components/ContentItemContainer';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import BasePeopleContainer from '~/app/shared-cards/components/BasePeopleContainer';
import BreadCrumbs from '~/app/shared-content-item/components/BreadCrumbs';
import ContentItemAdditional from '~/app/shared-content-item/components/ContentItemAdditional';
import ContentItemBackgroundPanel from '~/app/shared-content-item/components/ContentItemBackgroundPanel';
import ResourceContainer from '~/app/shared-content-item/components/ResourceContainer';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { PageContainer, PageBody } from '~/app/shared/components/DefaultPage';
import PageTitle from '~/app/shared/components/PageTitle/PageTitle';
import Text from '~/app/shared/components/Text';
import { useResource } from '~/app/shared/hooks';
import { get } from 'lodash-es';

import EventTypeContent from '../EventTypeContent';
import EventTypeContextMenu from '../EventTypeContextMenu';
import { DefaultContentItemHeader } from '~/features/contentitems/components/DefaultContentItemHeader';
import { getEventTypeAssignmentTimelineItems } from '~/features/event_types/utils/getEventTypeAssignmentTimelineItems';

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

interface EventTypeDetailPageProps {
  content: ContentItem;
  isRequired?: boolean;
}

function EventTypeDetailPage(props: EventTypeDetailPageProps) {
  const { content: baseContent, isRequired = true } = props;

  // This is temporary until we completely remove the redux sagas usage.
  // This is necessary because it is possible to have multiple assignments in the same state because of the save/drop behavior.
  const currentAssigment = useLatestContentItemAssignment(baseContent.id);
  const content = {
    ...baseContent,
    user_assignment:
      currentAssigment || get(baseContent, 'user_assignment', get(baseContent, 'assignment')),
  };

  const isRenderedWithinTrack = useIsRenderedWithinTrack();
  const timeline = getEventTypeAssignmentTimelineItems(content);

  const { showResource } = useResource(content, LEARNING_TYPES.event_types);
  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { engagedPeopleCount } = useContentPeople(content, ['active', 'terminated']);
  const pageTitle = content.name ? `${content.name} events` : '';

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      {!isRenderedWithinTrack && <PageTitle title={pageTitle} />}
      <ContentItemBackgroundPanel height="120px" $isTrackItem={isRenderedWithinTrack} />
      {!isRenderedWithinTrack && <BreadCrumbs content={content} />}
      <PageContainer columnsWidth="1fr">
        <DefaultContentItemHeader
          contentItem={content}
          contextMenu={
            <EventTypeContextMenu
              content={content}
              contextMenuExtraProps={{ isTrackItem: isRenderedWithinTrack }}
            />
          }
          PrimaryAction={null}
          timeline={timeline}
          isOptional={!isRequired}
        />

        <PageBody>
          {/* note: when work in the event type body revamp task, try to use the ContentItemBody */}
          <BodyContent>
            <EventTypeContent content={content} />
            <ContentItemAdditional>
              {showResource && <ResourceContainer content={content} />}
              {showPeople && (
                <BasePeopleContainer
                  contentType={LEARNING_TYPES.event_types}
                  organizers={organizers}
                  maintainers={maintainers}
                  engagedPeople={engagedPeople}
                  engagedPeopleCount={engagedPeopleCount}
                  engagedPeopleHeading={
                    <Text>
                      {engagedPeopleCount} {engagedPeopleCount > 1 ? 'people' : 'person'} engaged
                    </Text>
                  }
                />
              )}
            </ContentItemAdditional>
          </BodyContent>
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
}

export default EventTypeDetailPage;
