import { get } from 'lodash-es';
import { ContentItem } from '~/app/shared-content-item/interfaces';

export function parseAssignmentDates(contentItem: ContentItem) {
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  let assignment = contentItem.user_assignment;

  if (assignment) {
    return {
      assignment,
      assignedDate: get(assignment, 'created_at', null) as unknown as string,
      startedDate: get(assignment, 'progress.started_at', null) as unknown as string,
      dueDate: get(assignment, 'due_at', null) as unknown as string,
      completedDate: get(assignment, 'completion.completed_at', null) as unknown as string,
      droppedDate: get(assignment, 'drop.dropped_at', null) as unknown as string,
      exemptedDate: get(assignment, 'exemption.exempted_at', null) as unknown as string,
    };
  }

  assignment = contentItem?.assignment;
  return {
    assignment,
    assignedDate: get(assignment, 'created', null) as unknown as string,
    startedDate: get(assignment, 'started_at', null) as unknown as string,
    dueDate: get(assignment, 'due_date', null) as unknown as string,
    completedDate: get(assignment, 'completed_datetime', null) as unknown as string,
    droppedDate: get(assignment, 'drop.dropped_at', null) as unknown as string,
    exemptedDate: get(assignment, 'exemption.exempted_at', null) as unknown as string,
  };
}
