import { ContentItemHeader } from '~/common/components/ContentItemHeader';
import ChannelByline from '~/app/shared/components/ChannelByline';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { useContentPeople } from '~/app/catalog/hooks';
import { get } from 'lodash-es';
import { useIsRenderedWithinTrack } from '~/features/tracks/hooks/useIsRenderedWithinTrack';
import EventContextMenu from '~/scenes/EventDetail/EventContextMenu';
import { useCurrentUser, useIsPreviewQueryParam } from '~/app/shared/hooks';
import EventActionButtons from '~/scenes/EventDetail/EventActionButtons';
import { Box, Stack } from '@mui/material';
import EventInfoContainerContent from '~/scenes/EventDetail/EventInfoContainerContent';
import { getContentItemFreshness } from '~/features/contentitems/utils/getContentItemFreshness';
import { AssignmentTimeline } from '~/features/contentitems/components/AssignmentTimeline';
import { AssignmentActionsProvider } from '~/features/contentitems/components/AssignmentActionsProvider';
import { ContentRatingActions } from '~/common/components/ContentRatingActions';
import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { getEnrollmentTimelineItems } from '../utils/getEnrollmentTimelineItems';

type EventContentItemHeaderProps = {
  contentItem: ContentItem;
  isOptional?: boolean;
};

function EventContentItemHeader(props: EventContentItemHeaderProps) {
  const { contentItem, isOptional = false } = props;

  const currentUser = useCurrentUser();
  const isPreviewMode = useIsPreviewQueryParam();
  const isRenderedWithinTrack = useIsRenderedWithinTrack();
  const { maintainers } = useContentPeople(contentItem);

  const assignment = contentItem.user_assignment || contentItem.assignment;
  const assignmentState = get(assignment, 'state');

  const tags = get(contentItem, 'tags', []);
  const isArchived = get(
    contentItem,
    'is_archived',
    Boolean(get(contentItem, 'archival.archived_at'))
  );
  const isInline = get(contentItem, 'is_inline', false);
  const freshnessInfo = getContentItemFreshness(contentItem);

  const timelineItems = getEnrollmentTimelineItems(contentItem);

  const canClickTitle = !isInline && isRenderedWithinTrack;
  const canDisplayAssignmentActions = !isRenderedWithinTrack && !isArchived;

  return (
    <ContentItemHeader>
      <ContentItemHeader.HeadingArea>
        {contentItem.cover && <ContentItemHeader.Cover component="img" src={contentItem.cover} />}
        <ContentItemHeader.ContentArea
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          <Box>
            <ContentItemHeader.TitleArea>
              <ContentItemHeader.Title
                contentItem={contentItem}
                isClickable={canClickTitle}
                isOptional={isOptional}
                subTitle={<ContentItemHeader.Metadata contentItem={contentItem} />}
              />

              <ContentItemHeader.ContextMenuArea>
                <EventContextMenu content={contentItem} contextMenuExtraProps={{ currentUser }} />
              </ContentItemHeader.ContextMenuArea>
            </ContentItemHeader.TitleArea>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <EventInfoContainerContent content={contentItem} />

            {(contentItem.channel || freshnessInfo) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {contentItem.channel && <ChannelByline channel={contentItem.channel} />}

                <Box flex={1} />

                {freshnessInfo && (
                  <ContentItemHeader.Freshness info={freshnessInfo} maintainers={maintainers} />
                )}
              </Box>
            )}

            {tags.length > 0 && <ContentItemHeader.TagList tags={tags} />}
          </Box>
        </ContentItemHeader.ContentArea>
      </ContentItemHeader.HeadingArea>

      <ContentItemHeader.FooterArea>
        {timelineItems.length > 0 && <AssignmentTimeline items={timelineItems} />}

        <Box flex={1} />

        {canDisplayAssignmentActions && (
          <AssignmentActionsProvider content={contentItem} assignment={assignment}>
            <Stack direction="row" gap="12px" alignItems="center">
              {assignment && assignmentState === ASSIGNMENT_STATES.completed && (
                <ContentRatingActions content={contentItem} assignment={assignment} />
              )}

              {!isPreviewMode && <EventActionButtons content={contentItem} />}
            </Stack>
          </AssignmentActionsProvider>
        )}
      </ContentItemHeader.FooterArea>
    </ContentItemHeader>
  );
}

export { EventContentItemHeader };
