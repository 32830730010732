import { ASSIGNMENT_STATES } from '~/app/assignments/constants';
import { useAssignmentActionsCtx } from '~/features/contentitems/contexts/AssignmentActionsCtx';
import { ContentItem } from '~/app/shared-content-item/interfaces';
import { get, toNumber, isNil } from 'lodash-es';
import { CheckIcon } from '~/vendor/mui-icons';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button } from '@mui/material';

interface PrimaryActionButtonProps {
  content: ContentItem;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content } = props;

  const { is_completable: isCompletable } = content;
  const assignment = content?.user_assignment || content?.assignment;

  const assignmentState = get(assignment, 'state');
  const { create, complete, isLoading } = useAssignmentActionsCtx();

  if (!isCompletable || assignmentState === ASSIGNMENT_STATES.completed) {
    return null;
  }

  const fractionWatchedForCompletion = toNumber(get(content, 'fraction_watched_for_completion', 0));

  if (fractionWatchedForCompletion > 0) {
    return (
      <Button variant="contained" startIcon={<PlayArrowIcon />} disabled>
        Watch to Complete
      </Button>
    );
  }

  const onMarkCompleteClick =
    isNil(assignment) || assignmentState === ASSIGNMENT_STATES.dropped
      ? () => create({ is_completed: true })
      : complete;

  return (
    <Button
      variant="contained"
      startIcon={<CheckIcon />}
      onClick={() => onMarkCompleteClick()}
      disabled={isLoading}
    >
      Mark Complete
    </Button>
  );
};

export default PrimaryActionButton;
