import * as React from 'react';

import colors from '~/services/colors';
import { Stack } from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { AssignmentTimelineItem } from '../types';

type AssignmentTimelineProps = {
  items: AssignmentTimelineItem[];
};

function AssignmentTimeline(props: AssignmentTimelineProps) {
  const { items } = props;

  return (
    <Stack
      direction="row"
      divider={
        <TrendingFlatIcon fontSize="small" htmlColor={colors.neutral400} sx={{ margin: '0 4px' }} />
      }
      alignItems="center"
    >
      {items.map((item) => (
        <React.Fragment key={item.key}>{item.element}</React.Fragment>
      ))}
    </Stack>
  );
}

export { AssignmentTimeline };
