import {
  Root,
  Cover,
  ContentArea,
  HeadingArea,
  TitleArea,
  Title,
  Freshness,
  ContextMenuArea,
  FooterArea,
} from './ContentItemHeader';
import { Metadata } from './Metadata';
import { TagList } from './TagList';

export const ContentItemHeader = Object.assign(Root, {
  Cover,
  ContentArea,
  HeadingArea,
  TitleArea,
  Title,
  Metadata,
  Freshness,
  TagList,
  ContextMenuArea,
  FooterArea,
});
